// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'es',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: 'c56e61a7fa6e2c09354e24ec5a648c978c053c1e',
    releaseTagRef: '386ebb5',
    releaseTimestamp: '1744184102',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    urlScheme: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
    },
    apiKeys: {
      amplitude: '4eb1410f0ccba225fbbb8d3d8b374fab',
      branch: '',
      braze: '7a65406b-fb1c-4d40-a734-e992c9fb4c04',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyCddOl-f8akjVVtK__m2VvLMe5bhXYr2AY',
      tomtom: '',
      launchDarkly: '6446873cbd71a5128fd8fca5',
      launchDarklyMobileKey: 'mob-4a6a4448-fb1b-4fb4-b568-36e5ff202843',
      mParticle: 'eu1-df5f1ea3d94b4644aac0895306f38db0',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: 'com.plk.es.sociallogin',
        redirectURI: 'https://plk-es.firebaseapp.com/__/auth/handler',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '734469811566302',
        redirectURI: 'https://www.popeyes.es/',
      },
    },
    aws: {
      gqlApiUrl: 'https://euw3-prod-plk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euw3-prod-plk-gateway.rbictg.com/graphql',
      region: 'eu-west-3',
      userPoolClientId: '21aqub4at7qqkgmtuejh7mq4as',
      userPoolId: 'eu-west-3_cWKTeo1KI',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '929265965346-p6a38mh98osih16bdsgto5bem430hhl6.apps.googleusercontent.com',
        redirectURI: 'https://plk-es.firebaseapp.com/__/auth/handler',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: 'C0003',
        consentCategoryMParticle: 'C0001',
        globalConsentState: 'Opt-In',
        scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        scriptId: 'd2e7cd3e-15ff-4278-b47e-93769467accd',
      },
      deleteAccountUrl: 'https://privacyportal-de.onetrust.com/webform/67b80636-41d6-4b37-aea3-717da0a9526f/b0375e87-b012-4340-b147-500a391ff30a',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: '',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: 'HH:mm',
    dateFormat: 'dd/MM/yyyy',
    addressFormat: 'name_number',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: 'C0004',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: 'C0002',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '6e0ff2b5-5af0-4875-acd4-43a82af3b080',
        },
      },
    }
  };